import React, { useState, createContext } from "react";
import storage from "../services/storageService";
import { useLocation } from "react-router-dom";

export const AppContext = createContext();

export const ContextProvider = (props) => {
  const [user, setUser] = useState(
    { ...storage?.get("user") } || {}
  );
  // const location = useLocation();
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const [openMenuList, setOpenMenuList] = useState([]);
  const navigatorLang = navigator.language.split(/[-_]/)[0];
  const supportedLangs = ["KZ", "RU"];
  const [lang, setLanguage] = useState(
    // storage?.get("language") ||
    //   (supportedLangs.includes(navigatorLang.toUpperCase())
    //     ? navigatorLang
    //     : "kz")
    () => {
      let pathname = window.location.pathname;
      if (pathname === '/')
        return 'ru';
      else if (pathname === '/kz')
        return 'kz';
      else if (storage?.get("language"))
        return storage?.get("language")
      else if(supportedLangs.includes(navigatorLang.toUpperCase()))
          return navigatorLang;
      else 
        return "kz"
    }
  );
  
  if (lang === "kz") {
    storage?.set("momentLang", "kk");
  } else if (lang === "ru") {
    storage?.set("momentLang", "ru");
  } else {
    // storage?.set("momentLang", "en");
    storage?.set("momentLang", "kk");
  }

  const setLang = (language) => {
    storage?.set("language", language);
    setLanguage(language);
  };

  const userRole =
    user && user.roles
      ? user.roles.includes("SUPER_ADMIN")
        ? "SUPER_ADMIN"
        : user.roles.includes("MODERATOR")
        ? "MODERATOR"
        : user.roles.includes("EDITOR")
        ? "EDITOR"
        : user.roles.includes("TEACHER")
        ? "TEACHER"
        : user.roles.includes("SCHOOL_ADMIN")
        ? "SCHOOL_ADMIN"
        : user.roles.includes("SALESMAN")
        ? "SALESMAN"
        : user.roles.includes("EXPERT")
        ? "EXPERT"
        : user.roles.includes("CUSTOMER")
        ? "CUSTOMER"
        : "STUDENT"
      : "STUDENT";

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        lang,
        setLang,
        userRole,
        isSideBarOpen,
        setSideBarOpen,
        openMenuList,
        setOpenMenuList,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
